<template>
    <v-container>
        <base-hero-simple class="mb-2">
            <base-router-links :items="links" />
        </base-hero-simple>
        <v-card v-if="!hasOrg" style="margin-top: 25px">
            <v-card-title class="text-h5"> Cannot find Organization ID </v-card-title>

            <v-card-subtitle>You currently do not have access to view this page.<br /> </v-card-subtitle>
        </v-card>
        <div v-if="hasOrg">
            <iframe style="width: 100%; height: 100vh" :srcdoc="dashboardHtml"></iframe>
        </div>
    </v-container>
</template>

<script>
import { navLinks } from '../navigation.js'
import { getDashboard } from '@/api'
import gql from 'graphql-tag'

export default {
    metaInfo: { title: `Your Account`, subTitle: `Dashboard` },
    name: `Dashboard`,
    apollo: {
        queryOrg: {
            query: gql`
                query QueryOrg {
                    queryOrg {
                        gssOrgId
                        orgId
                        orgName
                    }
                }
            `,
            update(data) {
                if (data.queryOrg && data.queryOrg.gssOrgId) {
                    this.gssOrgId = data.queryOrg.gssOrgId
                    this.getDash(this.gssOrgId)
                } else if (!data.queryOrg) {
                    this.hasOrg = false
                }
            }
        }
    },
    data() {
        return {
            links: navLinks,
            hasOrg: true,
            dashboardHtml: ''
        }
    },
    methods: {
        async getDash(id) {
            await getDashboard({
                id: id
            })
                .then(({ data }) => {
                    this.dashboardHtml = data
                })
                .catch((e) => {})
        }
    }
}
</script>
